.login {
    background: #24416b;
    min-height: 100vh;
    padding: 20px;
    .login-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background: #fff;
        width: 320px;
        margin: 7% auto;
        padding: 20px;
    }
}