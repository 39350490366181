#sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    float: left;
    background: #24416b;
    max-width: 250px;
    padding-top: 30px;
    .rs-sidenav-body {
        padding-top: 60px;
    }
    .rs-sidenav-nav {
        background: #24416b;
        height: 100%;
        a {
            background: transparent;
            color: #fff;
            &:hover {
                background: #fff;
                color: #24416b;
            }
            &.rs-sidenav-item-active {
                background: #fff;
                color: #24416b;
            }
        }
    }
    .rs-sidenav-toggle {
        background: transparent;
        border: 0;
        .rs-sidenav-toggle-button {
            background: transparent;
            background: rgb(29, 55, 90);
            color: #fff;
            padding: 10px 10px 10px 18px !important;
            
            .rs-ripple-pond {
                background: transparent;
                color: #fff;
                &:hover {
                    color: #24416b;
                }
            }
        }

    }
}