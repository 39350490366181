.App {
  text-align: center;
  table {
    margin: 0 auto;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  margin-bottom: 60px !important;
  color: #24416b;
}

.rs-btn-link {
  color: #24416b;
  &:hover, &:focus {
    color: #24416b;
  }
}
.rs-table-cell-header-icon-sort {
  color: #24416b;
}

.rs-btn-primary {
  background-color: #24416b;
  color: #fff;
  &:hover {
    background: rgb(29, 55, 90);
  }
}


.table-content-editing {
  .rs-input, 
  .rs-picker {
      left: 5px;
      position: absolute;
      top: 5px;
      width: 90%;
  }
}

.content > .rs-loader-wrapper.rs-loader-speed-normal {
  position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 50%;
    margin-left: -9px;
}

.rs-table.rs-table-hover + .rs-loader-wrapper.rs-loader-speed-normal {
  display: flex;
  place-content: center;
  position: absolute;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  padding: 4px 0px;
  top: 0px;
  .rs-loader {
    position: absolute;
    top: 50%;
  }
}

.rs-message-container {
  padding: 8px 28px 8px 20px !important;
}

.error {
  border-color: red;
  &:hover {
    border-color: red !important;
  }
  &:focus {
    outline: 3px solid #ff00003d;
    border-color: red;
  }
}